<template lang="pug">
div
  .row
    .col-xxl-8
      .card.card-custom.gutter-b.example.example-compact(v-if="isReady")
        .card-header.pb-0
          .card-title
            h3.card-label
              | {{ $t('biodiversity.station_detail') }}
          .card-toolbar
            .example-tools.justify-content-center
        .card-body.pt-3.pb-0
          b-row
            .col-md-4
              b-form-group#fieldset-1(description="" :label="$t('biodiversity.station_code')" label-for="input-1")
                b-form-input#input-1(v-model="data.code" :state="!(data.code == '')")
            .col-md-4
              b-form-group#fieldset-1(description="" :label="`${$t('biodiversity.station_name')} (TH)`" label-for="input-1")
                b-form-input#input-1(v-model="data.name_th" :state="!(data.name_th == '')")
            .col-md-4
              b-form-group#fieldset-1(description="" :label="`${$t('biodiversity.station_name')} (EN)`" label-for="input-1")
                b-form-input#input-1(v-model="data.name_en" :state="!(data.name_en == '')")
            
          b-row
            .col-md-4
              b-form-group#fieldset-1(description="" :label="$t('biodiversity.province')" label-for="input-1")
                b-form-select(v-model="data.province" :options="provinceList" value-field="slug" text-field="name" placeholder="Province" :state="!(data.province == null)")
                  template(#first="")
                    b-form-select-option(value="null" disabled="") Please select
            .col-md-4
              b-form-group#fieldset-1(description="" :label="$t('biodiversity.station_lat')" label-for="input-1")
                b-form-input#input-1(v-model="data.lat" trim="" placeholder="Lat")
            .col-md-4
              b-form-group#fieldset-1(description="" :label="$t('biodiversity.station_lon')" label-for="input-1")
                b-form-input#input-1(v-model="data.lon" trim="" placeholder="Lon")
          b-form-group#fieldset-1(description="" :label="`${$t('biodiversity.station_description')} (TH)`" label-for="input-1")
            ckeditor(:editor="editor" v-model="data.description_th" :config="editorConfig")
          b-form-group#fieldset-1(description="" :label="`${$t('biodiversity.station_description')} (EN)`" label-for="input-1")
            ckeditor(:editor="editor" v-model="data.description_en" :config="editorConfig")
      b-overlay(:show="isSaving" no-wrap="")

    .col-xxl-4
      .card.card-custom.gutter-b(v-if="isReady")
        .card-header.pb-0
          .card-title
            h3.card-label
              | {{ $t('biodiversity.publish') }}
          .card-toolbar
            .example-tools.justify-content-center
        .card-body.pt-3.pb-0
          b-button.mb-5(variant="primary" block="" @click="savePost") Save
        b-overlay(:show="isSaving" no-wrap="")  
</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import * as Services from '../services.js';

import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Link from '@ckeditor/ckeditor5-link/src/link';
import List from '@ckeditor/ckeditor5-list/src/list';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';

export default {
  name: 'biodiversity_station_add',
  data() {
    return {
      isReady: false,
      isSaving: false,
      id: 0,
      mode: null,
      provinceList: null,
      data: {
        id: 0,
        code: '',
        name_th: '',
        description_th: '',
        name_en: '',
        description_en: '',
        lat: '',
        lon: '',
        province: '',
      },
      editor: ClassicEditor,
      editorConfig: {
        plugins: [Essentials, Autoformat, Bold, Italic, BlockQuote, Heading, Link, List, Paragraph, Alignment],
        toolbar: {
          items: [
            'heading',
            '|',
            'alignment',
            'bold',
            'italic',
            'bulletedList',
            'numberedList',
            'blockQuote',
            'undo',
            'redo',
          ],
        },
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: 'Biodiversity Data', route: '/admin/biodiversity'},
      {title: 'Station', route: '/admin/biodiversity/station'},
      {title: 'Add Station'},
    ]);

    this.getData();
    this.getProvinceList();
  },
  methods: {
    getData() {
      if (this.$route.name == 'biodiversity.edit_station') {
        this.mode = 'edit';
        this.$store
          .dispatch(Services.GET_STATION_DATA, {id: this.$route.params.id})
          .then(res => {
            this.isReady = true;
            this.data = res.data[0];
          })
          .catch(error => {
            // console.log(error);
          });
      } else {
        this.isReady = true;
        this.mode = 'add';
      }
    },
    getProvinceList() {
      this.$store
        .dispatch(Services.GET_PROVINCE_LIST)
        .then(res => {
          this.isReady = true;
          this.provinceList = res.data;
        })
        .catch(error => {
          // console.log(error);
        });
    },
    savePost() {
      return new Promise((resolve, reject) => {
        if (this.data.code == '' || this.data.name_th == '' || this.data.name_en == '' || this.data.province == '') {
          this.$bvToast.toast('Please complete the form', {
            title: 'Error!',
            variant: 'danger',
            solid: true,
          });
        } else {
          this._savePost();
          resolve();
        }
      });
    },
    _savePost() {
      this.isSaving = true;
      this.$store
        .dispatch(Services.SAVE_STATION_DATA, this.data)
        // go to which page after successfully login
        .then(res => {
          if (res.status) {
            this.post = res.data[0];
            // console.log('Save post');
            this.isSaving = false;
            this.$bvToast.toast('Post Saved.', {
              title: 'Successfully',
              variant: 'success',
              solid: true,
            });
            this.$router.push({
              name: 'biodiversity.list_station',
              params: {id: this.post.id},
            });
          } else {
            this.isSaving = false;
            this.$bvToast.toast(res.message, {
              title: 'Failed!',
              variant: 'danger',
              solid: true,
            });
          }
        })
        .catch(error => {
          this.isSaving = false;
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
